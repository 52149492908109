import $ from 'jquery';
import jQuery from 'jquery';

window.jQuery = jQuery;

import 'bootstrap';
import 'popper.js';

import Lazyload from 'vanilla-lazyload';

$(function () {

    let ll = new Lazyload({
        elements_selector: '.lazy'
    });
    ll.update();


    if ($(window).width() >= 992) {
        let lazybg = new Lazyload({
            elements_selector: '.lazy-bg'
        });
        lazybg.update();
    }

    if ($('#content-row').height() < $('#content-container').height()) {
        let diff = $('#content-container').height() - $('#content-row').height();
        $('#content-row').css('top', diff / 2);
    }

    $('.impressionen a').featherlight('image');
});

// $(document).ready(function () {
//     $('.second-button').on('click', function () {
//         $('.animated-icon2').toggleClass('open');
//     });

//     projekte = $('#projekte').offset().top;
//     console.log(projekte);
//     window.onresize = () => resize();
//     window.onscroll = () => {
//         let wh = $(window).height()
//         console.log(document.documentElement.scrollTop)
//         // if (document.body.scrollTop + wh > projekte || document.documentElement.scrollTop + wh > projekte) {
//         //     $("body").css("background-image", "url(projekte.jpg)");
//         // } else {
//         //     $("body").css("background-image", "url(pic.jpg)");
//         // }
//     };
// });

// function resize() {
//     let wh = $(window).height();
//     let ww = $(window).width();
//     // if (ww < 992) {
//     //     $('.content').css('background-image', 'none');
//     // }
// }
